<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :headers="tableMixin_displayedHeaders"
    :items="decoratedItems"
    :mobile-breakpoint="0"
    :class="{ 'content-loading': loading }"
    :loading="loading"
    disable-pagination
    disable-sort
    hide-default-footer
  >
    <template v-slot:item.quantity="{ item }">
      {{ +item.quantity }}
    </template>

    <template v-slot:item.unit_price="{ item }">
      {{ item.unit_price | currency }}
    </template>

    <template v-slot:item.total="{ item }">
      {{ item.total | currency }}
    </template>

    <template v-slot:item.vat_amount="{ item }">
      {{ item.vat_amount | currency }}
    </template>

    <template v-slot:item.price_with_vat="{ item }">
      {{ item.price_with_vat | currency }}
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.quantity>
          {{ +item.quantity }}
        </template>

        <template v-slot:item.unit_price>
          {{ item.unit_price | currency }}
        </template>

        <template v-slot:item.total>
          {{ item.total | currency }}
        </template>

        <template v-slot:item.vat_amount>
          {{ item.vat_amount | currency }}
        </template>

        <template v-slot:item.price_with_vat>
          {{ item.price_with_vat | currency }}
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import tableMixin from '@/mixins/table-mixin';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import clone from 'just-clone';

export default {
  name: 'InvoiceItemTable',

  components: { BaseExpandedTableRow },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    vatPercentage: {
      type: Number,
      default: 0,
    },
    includeVat: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers() {
      const headers = [
        {
          text: `${this.$t('invoices.labels.product')} / ${this.$t('invoices.labels.service')}`,
          value: 'title',
        },
        { text: this.$t('general.amount'), value: 'quantity', hidden: 'smAndDown' },
        { text: this.$t('invoices.labels.unit'), value: 'unit', hidden: 'smAndDown' },
        { text: this.$t('general.price'), value: 'unit_price', hidden: 'smAndDown' },
        { text: this.$t('general.sum'), value: 'total', hidden: 'smAndDown' },
      ];
      if (this.includeVat) {
        headers.push({ text: this.$t('general.vat'), value: 'vat_amount', hidden: 'smAndDown' });
        headers.push({
          text: this.$t('general.total'),
          value: 'price_with_vat',
          hidden: 'smAndDown',
        });
      }
      return headers;
    },

    decoratedItems() {
      const items = clone(this.items);

      if (!this.includeVat) {
        return items;
      }

      const vatMultiplier = this.vatPercentage / 100;

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        item.vat_amount = +item.unit_price * vatMultiplier * +item.quantity;
        item.price_with_vat = +item.total + item.vat_amount;
      }

      return items.map((i) => {
        const vatAmount = +i.unit_price * vatMultiplier * +i.quantity;
        return {
          ...i,
          vat_amount: vatAmount,
          price_with_vat: +i.total + vatAmount,
        };
      });
    },
  },
};
</script>
