<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on: onTooltip }">
      <v-menu v-model="isOpen" :close-on-content-click="false" bottom left offset-y>
        <template v-slot:activator="{ on: onMenu }">
          <v-btn v-on="{ ...onTooltip, ...onMenu }" :loading="loading" :disabled="loading" icon>
            <v-icon>edit</v-icon>
          </v-btn>
        </template>

        <v-date-picker
          :value="selectedInvoice.payment_received_at"
          :locale="locale"
          first-day-of-week="1"
          no-title
          scrollable
          @input="onDateInput"
        />
      </v-menu>
    </template>
    <div>
      {{ $t('invoices.controls.edit_payment_date') }}
    </div>
  </v-tooltip>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'InvoiceEditPaymentDate',

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    ...mapState('invoices', ['selectedInvoice']),
    ...mapState('settings', ['locale']),

    loading() {
      return this.$store.getters.loading[`put:api/finances/invoices/${this.selectedInvoice.id}`];
    },
  },

  methods: {
    ...mapActions('invoices', ['updateInvoice']),

    onDateInput(date) {
      this.updateInvoice({
        ...this.selectedInvoice,
        payment_received_at: date,
      });
      this.isOpen = false;
    },
  },
};
</script>

<style scoped></style>
