var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:{ 'content-loading': _vm.loading },attrs:{"expanded":_vm.tableMixin_expandedRows,"headers":_vm.tableMixin_displayedHeaders,"items":_vm.decoratedItems,"mobile-breakpoint":0,"loading":_vm.loading,"disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(+item.quantity)+" ")]}},{key:"item.unit_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.unit_price))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.vat_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.vat_amount))+" ")]}},{key:"item.price_with_vat",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.price_with_vat))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.quantity",fn:function(){return [_vm._v(" "+_vm._s(+item.quantity)+" ")]},proxy:true},{key:"item.unit_price",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("currency")(item.unit_price))+" ")]},proxy:true},{key:"item.total",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]},proxy:true},{key:"item.vat_amount",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("currency")(item.vat_amount))+" ")]},proxy:true},{key:"item.price_with_vat",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("currency")(item.price_with_vat))+" ")]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }