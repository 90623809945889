<template>
  <v-container fluid>
    <v-card>
      <v-row no-gutters>
        <!-- Title -->
        <v-col class="py-1" cols="12">
          <span class="text-h6 pl-3">
            {{ $t('general.invoice') }} {{ invoice.series }}{{ invoice.number }}
            <br v-if="$vuetify.breakpoint.xsOnly" />
            <template v-if="invoice.is_invalidated">
              <span :class="[{ 'px-3': $vuetify.breakpoint.xsOnly }, 'alert-text']">
                ({{ $t('invoices.general.invalid') }})
              </span>
              <br v-if="$vuetify.breakpoint.xsOnly" />
            </template>

            <template v-if="invoice.is_payment_received">
              <span :class="[{ 'px-3': $vuetify.breakpoint.xsOnly }, 'text-success']">
                ({{ $t('general.actions.paid') }}
                <span class="text-no-wrap">{{ invoice.payment_received_at }})</span>
              </span>
              <br v-if="$vuetify.breakpoint.xsOnly" />
            </template>
          </span>

          <span class="px-2">
            <InvoiceEditPaymentDate
              v-if="invoice.is_payment_received && $can(['invoices.create'])"
            />

            <v-menu v-if="!$isClient()" bottom offset-y @click.native.stop>
              <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      v-on="{ ...menu, ...tooltip }"
                      :loading="loading[`get:api/finances/invoices/download/${invoice.id}`]"
                      :disabled="loading[`get:api/finances/invoices/download/${invoice.id}`]"
                      icon
                    >
                      <v-icon>file_download</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('invoices.controls.download_pdf') }}
                  </span>
                </v-tooltip>
              </template>
              <v-list>
                <v-list-item @click="downloadInvoice({ invoice, lang: 'lt' })">
                  <v-list-item-title>
                    {{ $t('general.locale.in_lithuanian') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadInvoice({ invoice, lang: 'en' })">
                  <v-list-item-title>
                    {{ $t('general.locale.in_english') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-tooltip
              v-if="!invoice.is_invalidated && $can(['invoices.create']) && !$isClient()"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="onSendInvoiceClick">
                  <v-icon>email</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t('invoices.controls.send_via_email') }}
              </span>
            </v-tooltip>
          </span>

          <span
            v-if="
              !invoice.is_payment_received &&
              !invoice.is_invalidated &&
              $can(['invoices.create']) &&
              !$isClient()
            "
            class="px-2 float-right"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :disabled="loading[`put:api/finances/invoices/${invoice.id}/payment-received`]"
                  :loading="loading[`put:api/finances/invoices/${invoice.id}/payment-received`]"
                  icon
                  @click="markInvoiceAsPaid(invoice)"
                >
                  <v-icon>check</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t('invoices.controls.mark_as_paid') }}
              </span>
            </v-tooltip>

            <v-btn icon @click="editInvoice">
              <v-icon>edit</v-icon>
            </v-btn>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="invalidateInvoice(invoice)">
                  <v-icon>clear</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t('invoices.controls.invalidate') }}
              </span>
            </v-tooltip>
          </span>
        </v-col>

        <v-col cols="12">
          <v-divider class="mb-3" />
        </v-col>

        <!-- Seller block -->
        <v-col class="px-3" cols="12" sm="6">
          <h3 class="text-h6 mb-3">
            {{ invoice.seller_name }}
          </h3>
          <address>
            {{ invoice.seller_code }}
            <br />
            {{ invoice.seller_address }} <br />
            {{ invoice.seller_bank_account_details }}
            <br />
            <br />
            <span> {{ $t('general.common_contact_info.phone_short') }}: </span>
            <a :href="`tel:${invoice.seller_phone}`" class="link">
              &nbsp;{{ invoice.seller_phone }}
            </a>
            <br />
            <span> {{ $t('general.common_contact_info.email') }}: </span>
            <a :href="`mailto:${invoice.seller_email}`" class="link">
              &nbsp;{{ invoice.seller_email }}
            </a>
            <br />
            <br />
            <span> {{ $t('invoices.labels.signed_date') }}: </span>
            &nbsp;{{ invoice.signed_date }}
            <br />
            <span> {{ $t('invoices.general.series') }}: </span>
            &nbsp;{{ invoice.series }} Nr: {{ invoice.number }}
            <br />
            <br />
            <br />
          </address>
        </v-col>

        <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12">
          <v-divider class="mb-3"></v-divider>
        </v-col>

        <!-- Client block -->
        <v-col class="px-3 text-sm-right" cols="12" sm="6">
          <h3 class="text-h6">
            {{ $t('general.client') }}
          </h3>
          <p>
            <span class="font-size-20">{{ invoice.buyer_name }}</span>
          </p>
          <address>
            {{ invoice.buyer_code }}
            <br />
            {{ invoice.buyer_address }}
            <br />
            <span v-if="invoice.does_include_vat">
              {{ $t('invoices.general.vat_code') }}: {{ invoice.buyer_vat_code }}
            </span>
            <br />
            <br />
            <span> {{ $t('general.common_contact_info.phone_short') }}: </span>
            <a :href="`tel:${invoice.buyer_phone}`" class="link">
              &nbsp;{{ invoice.buyer_phone }}
            </a>
            <br />
            <span> {{ $t('general.common_contact_info.email') }}: </span>
            <a :href="`mailto:${invoice.buyer_email}`" class="link">
              &nbsp;{{ invoice.buyer_email }}
            </a>
            <br />
            <br />
          </address>
          <span>
            <strong> {{ $t('general.date_and_time.valid_until') }}: {{ invoice.due_date }} </strong>
          </span>
          <br />
          <br />
        </v-col>

        <v-col cols="12" v-if="invoice.items && invoice.items.length > 0">
          <InvoiceItemTable
            :items="invoice.items"
            :include-vat="invoice.does_include_vat"
            :vat-percentage="+invoice.vat_percentage"
          />
        </v-col>

        <v-col class="px-3 mt-4 text-right" cols="12">
          <p v-if="invoice.does_include_vat">
            {{ $t('invoices.labels.sum_without_vat') }}:
            <strong>
              <span v-if="invoice.subtotal">
                {{ invoice.subtotal | currency }}
              </span>
            </strong>
          </p>
          <p v-if="invoice.does_include_vat">
            {{ $t('general.vat') }} ({{ invoice.vat_percentage | percentage }}):
            <strong>
              <span v-if="invoice.total_vat">
                {{ invoice.total_vat | currency }}
              </span>
            </strong>
          </p>
          <p v-if="invoice.does_include_vat" class="page-invoice-amount">
            <strong>
              {{ $t('invoices.labels.sum_with_vat') }}:
              <span v-if="invoice.total">
                {{ invoice.total | currency }}
              </span>
            </strong>
          </p>
          <p v-if="!invoice.does_include_vat" class="page-invoice-amount">
            <strong>
              {{ $t('invoices.labels.sum_total') }}:
              <span v-if="invoice.total">
                {{ invoice.total | currency }}
              </span>
            </strong>
          </p>
        </v-col>
      </v-row>
    </v-card>

    <router-view />
  </v-container>
</template>

<script>
import store from '@/store';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import InvoiceItemTable from '@/components/tables/InvoiceItemTable';
import InvoiceEditPaymentDate from '@/components/forms/InvoiceEditPaymentDate';

export default {
  name: 'Invoice',

  components: {
    InvoiceEditPaymentDate,
    InvoiceItemTable,
  },

  computed: {
    ...mapState('invoices', ['selectedInvoice']),
    ...mapGetters(['loading']),

    invoice() {
      return this.selectedInvoice;
    },
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('invoices/selectInvoice', +to.params.invoiceId)
      .then(() => {
        next();
      })
      .catch(() => {
        next(to.meta.getBackRoute());
      });
  },

  methods: {
    ...mapMutations('invoices', ['SET_INVOICE_FOR_SENDING']),
    ...mapActions('invoices', ['markInvoiceAsPaid', 'invalidateInvoice', 'downloadInvoice']),

    editInvoice() {
      this.$router.push({ name: 'editInvoice' });
    },

    onSendInvoiceClick() {
      const emails = [];
      const email =
        this.selectedInvoice.client?.email_for_invoices || this.selectedInvoice.client?.email;
      if (email) {
        emails.push(email);
      }

      this.SET_INVOICE_FOR_SENDING({
        emails,
      });
      this.$router.push({ name: 'sendInvoice' });
    },
  },
};
</script>

<style scoped>
address {
  font-style: normal;
}
</style>
